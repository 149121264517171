require('./bootstrap');

require('alpinejs');
require('@fortawesome/fontawesome-free/js/all');

import flatpickr from "flatpickr";

flatpickr(".datepicker", {
    weekNumbers: true,
    dateFormat: "Y-m-d",
    locale: {
        firstDayOfWeek: 1 // start week on Monday
    }
});

flatpickr(".rangepicker", {
    weekNumbers: true,
    wrap: true,
    dateFormat: "Y-m-d",
    mode: "range",
    locale: {
        firstDayOfWeek: 1 // start week on Monday
    }
});

$('[data-toggle="popover"]').popover();

function togglePercentage() {
    [...document.getElementsByClassName('i-display-amount')].forEach((element) => {
        element.classList.toggle('d-none');
    });

    [...document.getElementsByClassName('i-display-percent')].forEach((element) => {
        element.classList.toggle('d-none');
    });

    [...percentageButtons].forEach((button) => {
        [...button.getElementsByClassName('i-have-value')].forEach((element) => {
            element.classList.toggle('d-none');
        });
    });
}

let percentageButtons = document.getElementsByClassName('i-switch-to-percentages');

[...percentageButtons].forEach((element) => {
    element.addEventListener('click', () => {
        togglePercentage();
    });
});

let toggleButtons = document.getElementsByClassName('i-toggle');

[...toggleButtons].forEach((element) => {
    element.addEventListener('click', () => {
        [...document.getElementsByClassName(element.dataset.target)].forEach((element) => {
            element.classList.toggle('d-none');
        });

        [...element.getElementsByClassName('i-have-value')].forEach((element) => {
            element.classList.toggle('d-none');
        });
    });
});
